import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticateUser } from "./redux/actions/index";
import Auth from "./_services/auth";
import LoginPage from "./_components/login.component";
import SignInOutContainer from "./container/index";
import Cookies from "universal-cookie";
function Login() {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const isLoading = useSelector((state) => state.isLoading);

  const [loaded, setLoaded] = React.useState(false);

  const authenticateCallback = React.useCallback(() => {
    const token = cookies.get("token", { doNotParse: true });
    let body = new FormData();
    body.append("token", token);
    dispatch(authenticateUser(body));
  }, [dispatch]);

  React.useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      if (!isAuthenticated) {
        authenticateCallback();
      }
    }
    if (isAuthenticated) {
      navigate("/");
    }
  }, [authenticateCallback, isAuthenticated, navigate, loaded, setLoaded]);

  return (
    <>
      {
        <div
          style={{
            backgroundImage: 'url("/images/coca-cola-background.png")',
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100vw",
            height: "100vh",
            padding: "20px",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <LoginPage />
          </div>
        </div>
      }
    </>
  );
}

export default Login;
